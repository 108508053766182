@tailwind base;
@tailwind components;
@tailwind utilities;

/* antdesign css */
.editable-row .editable-cell-value-wrap {
  border: 1px solid transparent;
  border-radius: 6px;
  min-height: 32px;
  padding: 4px 11px;
  margin-left: -12px;
  margin-right: -12px;
  max-height: 64px;
  overflow-y: auto;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.editable-row .ant-form-item {
  margin: 0 -12px;
}

/* To fix an issue where the success icon color is not being applied */
.ant-form-item-feedback-icon-success {
  color: #41cc4f;
}

/* temp fix for ant design notification flickering bug when > 1 notification is shown */
.ant-notification-stack > .ant-notification-notice-wrapper {
  transition:
    transform 0.3s,
    backdrop-filter 0s !important;
}

.ant-modal .ant-modal-body {
  overflow-y: auto;
  max-height: 70vh;
}

.ant-dropdown-menu .ant-dropdown-menu-title-content {
  width: 100%;
}

.ant-tour {
  width: 305px !important;
}

/* Fix for antd ui alignment bug */
.ant-select-tree-switcher {
  line-height: 20px !important;
}

/* To keep antd table border radius */
.ant-table-fixed-column {
  overflow: hidden;
}
