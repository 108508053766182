.rich-text-editor {
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  &.focused {
    border-color: #4142c9;
  }

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
}

.rich-text-editor-header {
  height: 40px;
  padding: 4px 11px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
}

.rich-text-editor-content {
  padding: 12px 11px;
  overflow: auto;
  resize: vertical;

  > div {
    outline: none !important;
    height: 100%;
  }
}

.rich-text-editor-counter {
  text-align: right;
  color: rgba(0, 0, 0, 0.45);

  &.error {
    color: #ff4d4f;
  }
}
