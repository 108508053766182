.collapsible-container {
  position: relative;
  width: 100%;
}

.collapsible-container-content {
  overflow: clip;
}

.collapsible-container-action {
  height: 85px;
  bottom: 0;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.28) 0%, #fff 61.1%);
}

.collapsible-container-button {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
