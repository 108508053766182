.imageThumbnail {
  border-color: #d9d9d9;
}
.imageThumbnail:hover {
  border-color: #676bd6 !important;
}

.imageThumbnailSelected,
.imageThumbnail:hover.imageThumbnailSelected {
  border-color: rgba(65, 66, 201) !important;
  box-shadow: 0px 0px 0px 2px rgba(65, 66, 201, 0.1);
}
