.imageDetailTable :global(.ant-table-cell) {
  border: 0 !important;
  padding: 4px 0px !important;
}

.imageDetailCollapsible :global(.ant-collapse-item) {
  border-color: rgb(240, 240, 240) !important;
}

.imageDetailCollapsible :global(.ant-collapse-content) {
  border-color: rgb(240, 240, 240) !important;
}
