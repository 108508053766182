.rich-text-field {
  table {
    margin: 0;
    width: 100%;
    table-layout: auto;
    overflow: hidden;
    margin-bottom: 1em;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0 !important;

    td,
    th {
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      padding: 6px 8px;
      position: relative;
      border-bottom: none;
      border-right: none;
      text-align: left;
      min-width: 1em;
      vertical-align: top;
      border-radius: 0 !important;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #fafafb;
    }
  }

  h1 {
    font-size: 18px !important;
  }

  h2 {
    font-size: 16px !important;
  }

  h3 {
    font-size: 15px !important;
  }

  h1,
  h2,
  h3 {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    line-height: 1.33 !important;
  }

  h4,
  h5,
  h6 {
    font-size: 14px !important;
  }

  p {
    line-height: 1.25 !important;
    min-height: 17.5px !important;
  }

  li {
    line-height: 1.33 !important;
  }

  li > p {
    margin-bottom: 0;
    line-height: 1.33 !important;
  }
}
